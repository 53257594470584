var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "fill-height", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "fill-height",
          attrs: { align: "center", justify: "center", "no-gutters": "" }
        },
        [
          _vm.loading
            ? _c("loading-symbol", {
                attrs: {
                  size: 120,
                  color: _vm.$vuetify.theme.currentTheme.primary
                }
              })
            : _vm._e(),
          !_vm.loading
            ? _c(
                "v-col",
                { attrs: { lg: "4", md: "6", sm: "8", cols: "10" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-h4 text-center",
                            attrs: { id: "titleText" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.updatePasswordView
                                    ? _vm.$t(
                                        "authentication.invitationSignUpPage.lastStepTitle"
                                      )
                                    : _vm.$t(
                                        "authentication.invitationSignUpPage.invitationTitle.bottom"
                                      )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "my-12",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.xs ? "12" : "10"
                          }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              !_vm.updatePasswordView
                                ? _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "emailField",
                                          filled: "",
                                          label: _vm.$t(
                                            "authentication.invitationSignUpPage.emailLabel"
                                          ),
                                          rules: [
                                            _vm.rules.emailRequiredSignup,
                                            _vm.rules.emailValid
                                          ],
                                          type: "email",
                                          "prepend-inner-icon": "email",
                                          "data-test": "email"
                                        },
                                        on: {
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.signUp($event)
                                          }
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          id: "temporaryPasswordField",
                                          autocomplete: "new-password",
                                          filled: "",
                                          label: _vm.$t(
                                            "authentication.invitationSignUpPage.tempPasswordLabel"
                                          ),
                                          type: "password",
                                          "prepend-inner-icon": "lock",
                                          rules: [
                                            _vm.rules.passwordRequired,
                                            _vm.rules
                                              .passwordAtLeast8Characters,
                                            _vm.rules.passwordContainsNumber,
                                            _vm.rules
                                              .passwordContainsSpecialCharacter,
                                            _vm.rules.passwordContainsBothCases
                                          ],
                                          "hide-details": "auto",
                                          "data-test": "password",
                                          hint: _vm.$t(
                                            "authentication.invitationSignUpPage.tempPasswordHint"
                                          ),
                                          "persistent-hint": ""
                                        },
                                        on: {
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.signUp($event)
                                          }
                                        },
                                        model: {
                                          value: _vm.temporaryPassword,
                                          callback: function($$v) {
                                            _vm.temporaryPassword = $$v
                                          },
                                          expression: "temporaryPassword"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("v-text-field", {
                                    staticClass: "mb-2",
                                    attrs: {
                                      id: "newPasswordField",
                                      autocomplete: "new-password",
                                      filled: "",
                                      label: _vm.$t(
                                        "authentication.invitationSignUpPage.newPasswordLabel"
                                      ),
                                      type: "password",
                                      "prepend-inner-icon": "lock",
                                      rules: [
                                        _vm.rules.passwordRequired,
                                        _vm.rules.passwordAtLeast8Characters,
                                        _vm.rules.passwordContainsNumber,
                                        _vm.rules
                                          .passwordContainsSpecialCharacter,
                                        _vm.rules.passwordContainsBothCases
                                      ],
                                      "hide-details": "auto",
                                      "data-test": "password"
                                    },
                                    on: {
                                      keydown: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.updatePassword($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.newPassword,
                                      callback: function($$v) {
                                        _vm.newPassword = $$v
                                      },
                                      expression: "newPassword"
                                    }
                                  })
                            ],
                            1
                          ),
                          _vm.errorMessage
                            ? _c("alert-message", {
                                attrs: {
                                  message: _vm.errorMessage,
                                  type: "error"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _vm.updatePasswordView
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "text-none",
                                  attrs: {
                                    id: "confirmButton",
                                    color: "primary",
                                    width: "100%"
                                  },
                                  on: { click: _vm.updatePassword }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "authentication.invitationSignUpPage.confirmButton"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass: "text-none",
                                  attrs: {
                                    id: "signUpButton",
                                    color: "primary",
                                    width: "100%"
                                  },
                                  on: { click: _vm.signUp }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "authentication.invitationSignUpPage.signUpButton"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mt-4", attrs: { cols: "10" } },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var hover = ref.hover
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-none text-body-1",
                                          attrs: {
                                            text: "",
                                            color: "vitrueGrey",
                                            width: "100%",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push("/")
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-chevron-left ")
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              class: hover
                                                ? "text-decoration-underline"
                                                : ""
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "authentication.invitationSignUpPage.backToLogin"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2646051142
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }