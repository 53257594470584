<template>
  <v-container class="fill-height" fluid>
    <v-row class="fill-height" align="center" justify="center" no-gutters>
      <loading-symbol
        :size="120"
        :color="$vuetify.theme.currentTheme.primary"
        v-if="loading"
      />
      <v-col v-if="!loading" lg="4" md="6" sm="8" cols="10">
        <v-row no-gutters justify="center">
          <v-col cols="12">
            <p id="titleText" class="text-h4 text-center">
              {{
                updatePasswordView
                  ? $t("authentication.invitationSignUpPage.lastStepTitle")
                  : $t(
                      "authentication.invitationSignUpPage.invitationTitle.bottom"
                    )
              }}
            </p>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? '12' : '10'" class="my-12">
            <v-form ref="form" v-model="valid" @submit.prevent>
              <div v-if="!updatePasswordView">
                <v-text-field
                  id="emailField"
                  filled
                  :label="$t('authentication.invitationSignUpPage.emailLabel')"
                  :rules="[rules.emailRequiredSignup, rules.emailValid]"
                  v-model="email"
                  type="email"
                  prepend-inner-icon="email"
                  @keydown.enter="signUp"
                  data-test="email"
                />
                <v-text-field
                  id="temporaryPasswordField"
                  autocomplete="new-password"
                  filled
                  class="mb-2"
                  :label="
                    $t('authentication.invitationSignUpPage.tempPasswordLabel')
                  "
                  v-model="temporaryPassword"
                  type="password"
                  prepend-inner-icon="lock"
                  :rules="[
                    rules.passwordRequired,
                    rules.passwordAtLeast8Characters,
                    rules.passwordContainsNumber,
                    rules.passwordContainsSpecialCharacter,
                    rules.passwordContainsBothCases
                  ]"
                  @keydown.enter="signUp"
                  hide-details="auto"
                  data-test="password"
                  :hint="
                    $t('authentication.invitationSignUpPage.tempPasswordHint')
                  "
                  persistent-hint
                />
              </div>
              <v-text-field
                id="newPasswordField"
                v-else
                autocomplete="new-password"
                filled
                class="mb-2"
                :label="
                  $t('authentication.invitationSignUpPage.newPasswordLabel')
                "
                v-model="newPassword"
                type="password"
                prepend-inner-icon="lock"
                :rules="[
                  rules.passwordRequired,
                  rules.passwordAtLeast8Characters,
                  rules.passwordContainsNumber,
                  rules.passwordContainsSpecialCharacter,
                  rules.passwordContainsBothCases
                ]"
                @keydown.enter="updatePassword"
                hide-details="auto"
                data-test="password"
              />
            </v-form>
            <alert-message
              v-if="errorMessage"
              :message="errorMessage"
              type="error"
            ></alert-message>
          </v-col>
          <v-col cols="10">
            <v-btn
              id="confirmButton"
              v-if="updatePasswordView"
              class="text-none"
              color="primary"
              width="100%"
              @click="updatePassword"
            >
              {{ $t("authentication.invitationSignUpPage.confirmButton") }}
            </v-btn>
            <v-btn
              id="signUpButton"
              v-else
              class="text-none"
              color="primary"
              width="100%"
              @click="signUp"
            >
              {{ $t("authentication.invitationSignUpPage.signUpButton") }}
            </v-btn>
          </v-col>
          <v-col cols="10" class="mt-4">
            <v-hover v-slot="{ hover }">
              <v-btn
                text
                color="vitrueGrey"
                class="text-none text-body-1"
                width="100%"
                @click="$router.push('/')"
                small
              >
                <v-icon> mdi-chevron-left </v-icon>
                <span :class="hover ? 'text-decoration-underline' : ''">{{
                  $t("authentication.invitationSignUpPage.backToLogin")
                }}</span>
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapMutations } from "vuex";
import { changeCurrentUserMemberStatus } from "@/customApi";
import AlertMessage from "@/components/account/AlertMessage";
import LoadingSymbol from "@/components/common/loaders/LoadingSymbol.vue";
import Rules from "@/utils/stringValidation";
import TeamService from "@/services/teamService.js";

export default {
  name: "SignUp",
  components: {
    AlertMessage,
    LoadingSymbol
  },
  data() {
    return {
      valid: false,
      email: this.signInEmail,
      temporaryPassword: "",
      newPassword: "",
      user: this.signedInUser,
      updatePasswordView: false,
      rules: Rules,
      errorMessage: "",
      loading: false
    };
  },
  props: {
    signInEmail: String,
    signedInUser: Object
  },
  mounted() {
    if (this.user && this.user.challengeName === "NEW_PASSWORD_REQUIRED") {
      this.updatePasswordView = true;
    }
  },
  methods: {
    ...mapMutations(["setAppLoading"]),
    async signUp() {
      this.errorMessage = "";
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      try {
        this.loading = true;
        this.user = await Auth.signIn(this.email, this.temporaryPassword);
        if (this.user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.updatePasswordView = true;
        } else {
          this.$router.push("/", () => {});
        }
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.loading = false;
      }
    },
    async updatePassword() {
      this.errorMessage = "";
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      try {
        this.loading = true;
        await Auth.completeNewPassword(this.user, this.newPassword, {
          email: this.email
        });
        await changeCurrentUserMemberStatus();

        //edge case wheereby user has not changed,
        //but we know something about them will have (admin checklist created)
        await TeamService.getTeamMemberInfo();
        //this forces feature intros to be re-fetched by he app component
        this.setAppLoading(false);

        this.$router.push("/", () => {});
      } catch (err) {
        if (err.message == "Could not find team member with this email") {
          this.loading = false;
          this.$router.push("/", () => {});
        }
        this.errorMessage = err.message;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
